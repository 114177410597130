import { Suspense, useRef, useState, useLayoutEffect } from "react";
import { Canvas, useLoader, useFrame } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";

import "../styles/Home.css";

import * as THREE from "three";

import EarthDayMap from "./../images/8k_earth_daymap.jpg";
import EarthNormalMap from "./../images/8k_earth_normal_map.jpg";
import EarthSpecularMap from "./../images/8k_earth_specular_map.jpg";
import EarthCloudsMap from "./../images/8k_earth_clouds.jpg";

import { Html, OrbitControls, Stars } from "@react-three/drei";

import gsap from "gsap";

function Scene() {
  const [colorMap, normalMap, specularMap, cloudsMap] = useLoader(
    TextureLoader,
    [EarthDayMap, EarthNormalMap, EarthSpecularMap, EarthCloudsMap]
  );
  const earthRef = useRef();
  const cloudsRef = useRef();
  const pointOne = useRef();
  const pointTwo = useRef();
  const pointThree = useRef();
  const pointFour = useRef();
  const pointFive = useRef();
  const pointSix = useRef();
  const pointSeven = useRef();
  const pointEight = useRef();
  const pointNine = useRef();
  const pointTen = useRef();
  const pointEleven = useRef();
  const pointTwelve = useRef();
  const pointThirteen = useRef();
  const pointFourteen = useRef();
  const pointFifteen = useRef();
  const pointSixteen = useRef();
  const pointSeventeen = useRef();
  const pointEighteen = useRef();
  const pointNineteen = useRef();

  const group = useRef();
  const pointLine = useRef();

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime();
    earthRef.current.rotation.y = a / 18;
    cloudsRef.current.rotation.y = a / 15;

    group.current.rotation.y = a / 18;
    //In order to rotate items about an axis, first wrap them in a group then call rotate on the group!
  });

  /* useThree(({ camera }) => {
         camera.position.z = 12;
      camera.lookAt(0, 0, 0);
    camera.position.y = 2;
  }); */
  // Here, we can access the camera via the useThree hook     -- https://stackoverflow.com/questions/72744898/how-to-set-default-camera-position-directly-over-glb-model-with-react-three-fib

  useLayoutEffect(() => {
    pointOne.current.lookAt(0, 0, 0);
    pointTwo.current.lookAt(0, 0, 0);
    pointThree.current.lookAt(0, 0, 0);
    pointFour.current.lookAt(0, 0, 0);
    pointFive.current.lookAt(0, 0, 0);
    pointSix.current.lookAt(0, 0, 0);
    pointSeven.current.lookAt(0, 0, 0);
    pointEight.current.lookAt(0, 0, 0);
    pointNine.current.lookAt(0, 0, 0);
    pointTen.current.lookAt(0, 0, 0);
    pointEleven.current.lookAt(0, 0, 0);
    pointTwelve.current.lookAt(0, 0, 0);
    pointThirteen.current.lookAt(0, 0, 0);
    pointFourteen.current.lookAt(0, 0, 0);
    pointFifteen.current.lookAt(0, 0, 0);
    pointSixteen.current.lookAt(0, 0, 0);
    pointSeventeen.current.lookAt(0, 0, 0);
    pointEighteen.current.lookAt(0, 0, 0);
    pointNineteen.current.lookAt(0, 0, 0);
  }, []);

  //This causes the points to look outward!!!

  /*  useFrame(() => {
    if (mainCamera.current) {
      mainCamera.current.position.z = 500;
    }
  }); */

  const [hoveredOne, setHoverOne] = useState(false);
  const [hoveredTwo, setHoverTwo] = useState(false);
  const [hoveredThree, setHoverThree] = useState(false);
  const [hoveredFour, setHoverFour] = useState(false);
  const [hoveredFive, setHoverFive] = useState(false);
  const [hoveredSix, setHoverSix] = useState(false);
  const [hoveredSeven, setHoverSeven] = useState(false);
  const [hoveredEight, setHoverEight] = useState(false);
  const [hoveredNine, setHoverNine] = useState(false);
  const [hoveredTen, setHoverTen] = useState(false);
  const [hoveredEleven, setHoverEleven] = useState(false);
  const [hoveredTwelve, setHoverTwelve] = useState(false);
  const [hoveredThirteen, setHoverThirteen] = useState(false);
  const [hoveredFourteen, setHoverFourteen] = useState(false);
  const [hoveredFifteen, setHoverFifteen] = useState(false);
  const [hoveredSixteen, setHoverSixteen] = useState(false);
  const [hoveredSeventeen, setHoverSeventeen] = useState(false);
  const [hoveredEighteen, setHoverEighteen] = useState(false);
  const [hoveredNineteen, setHoverNineteen] = useState(false);

  /*   const [active, setActive] = useState(false);
   */
  //let canvas = document.getElementById("canvas");
  //this method per Chris' video is not working

  const renderer = new THREE.WebGLRenderer({
    antialias: true,
    //canvas: canvas,
  });
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setPixelRatio(window.devicePixelRatio);

  function createPoint(lat, lng, country, population) {
    const point = new THREE.Mesh(
      new THREE.BoxGeometry(0.2, 0.2, 0.8),
      new THREE.MeshBasicMaterial({
        color: "#4CBB17",
        opacity: 0.4,
        transparent: true,
      })
    );

    // 23.6345° N, 102.5528° W = mexico
    const latitude = (lat / 180) * Math.PI;
    const longitude = (lng / 180) * Math.PI;
    const radius = 1;

    const x = radius * Math.cos(latitude) * Math.sin(longitude);
    const y = radius * Math.sin(latitude);
    const z = radius * Math.cos(latitude) * Math.cos(longitude);

    point.position.x = x;
    point.position.y = y;
    point.position.z = z;

    point.rotation.y = -Math.PI / 2;

    point.lookAt(0, 0, 0);

    point.geometry.applyMatrix4(
      new THREE.Matrix4().makeTranslation(0, 0, -0.4)
    );

    gsap.to(point.scale, {
      z: 1.4,
      duration: 2,
      yoyo: true,
      repeat: -1,
      ease: "linear",
      delay: Math.random(),
    });
    // box.scale.z =
    point.country = country;
    point.population = population;
  }

  createPoint({
    lat: 59.9343,
    lng: 30.3351,
    country: "St Petersburg",
    population: "NEOSPCC",
  });
  createPoint({
    lat: 47.3769,
    lng: 8.5417,
    country: "Zurich",
    population: "AX Labs",
  });
  createPoint({
    lat: 39.7392,
    lng: 104.9903,
    country: "Denver",
    population: "NEO News Today",
  });
  createPoint({
    lat: 48.3794,
    lng: 31.1656,
    country: "Ukraine",
    population: "Everstake",
  });

  createPoint({
    lat: 37.0902,
    lng: -95.7129,
    country: "USA",
    population: "328.2 million",
  });

  function findCor(lat, lon) {
    const latitude = (lat / 180) * Math.PI;
    const longitude = (lon / 180) * Math.PI;
    let radius = 5;

    const x = radius * Math.cos(latitude) * Math.sin(longitude);
    const y = radius * Math.sin(latitude);
    const z = radius * Math.cos(latitude) * Math.cos(longitude);

    return { x, y, z };
  }

  let point_1 = findCor(59.9343, 30.3351); //NPCCC
  let point_2 = findCor(47.3769, 8.5417); //Ax
  let point_3 = findCor(37.0902, -95.7129); //COZ
  let point_4 = findCor(1.3321, 103.8198); //Switcheo
  let point_5 = findCor(48.3794, 31.1656); //Everstake
  let point_6 = findCor(52.1326, 5.2913); //nash_io
  let point_7 = findCor(31.2304, 121.4737); //The NEO Order (SH)
  let point_8 = findCor(32.7767, -96.797); //Infstones(Dallas)
  let point_9 = findCor(60.472, 8.4689); //Norway (Flamingo)
  let point_10 = findCor(35.3617, 107.154); //Neoline (CN)
  let point_11 = findCor(37.9375, 14.3754); //Binance (Malta)
  let point_12 = findCor(33.2617, 110.3854); //ChainNode(CN)
  let point_13 = findCor(33.8617, 103.4954); //NeoBurger(CN)
  let point_14 = findCor(1.3821, 102.5198); //NGD2 (SG)
  let point_15 = findCor(61.9241, 25.7482); //Finland (Ghost Market)
  let point_16 = findCor(38.19241, -93.69); //NNT (Denver)
  let point_17 = findCor(40.4168, -3.7038); //Red4Sec (Madrid)
  let point_18 = findCor(-14.235, -51.9253); //NEO Research (Brazil)
  let point_19 = findCor(47.6062, -122.3321); //ngdenterprise (Redmond)

  console.log("this is point_1", point_1);
  console.log("this is point_2", point_2);
  console.log("this is point_8", point_8);
  console.log("this is point_3", point_3);

  let v1 = new THREE.Vector3(point_1.x, point_1.y, point_1.z);

  console.log("this is hoveredOne", hoveredOne);

  console.log("this is v1", v1);

  function getCurve(v1, v2) {
    let points = [];
    for (let i = 0; i <= 20; i++) {
      let p = new THREE.Vector3().lerpVectors(v1, v2, i / 20);
      p.normalize();
      p.multiplyScalar(5 + 0.3 * Math.sin((Math.PI * i) / 20));
      //Note- the first digit changed from 1 to 5 to match the earth's radius! If change radius, must change this!
      points.push(p);
    }

    let path = new THREE.CatmullRomCurve3(points);

    return path;
  }

  let sausage_1 = getCurve(point_7, point_19); //Shanghai to Redmond
  let sausage_2 = getCurve(point_5, point_15); //Ukraine to Finland
  let sausage_3 = getCurve(point_7, point_4); //SH to Switcheo
  let sausage_4 = getCurve(point_5, point_11); //Ukraine to Malta
  let sausage_5 = getCurve(point_10, point_4); //Switcheo to Neoline
  let sausage_6 = getCurve(point_17, point_11); //redforsec to Malta
  let sausage_7 = getCurve(point_1, point_12); //NPCCC to Chainnode
  let sausage_8 = getCurve(point_9, point_6); //Flm to nash_io
  let sausage_9 = getCurve(point_8, point_2); //Infostones to Ax
  let sausage_10 = getCurve(point_19, point_3); //ngdenterprise to COZ
  let sausage_11 = getCurve(point_18, point_8); //infstones to neoResearch

  return (
    <>
      <ambientLight intensity={1.5} />{" "}
      {/*       <pointLight color="#f6f3ea" position={[15, 4, 40]} intensity={2} />
       */}{" "}
      <Stars
        radius={300}
        depth={60}
        count={15000}
        factor={7}
        saturation={0}
        fade={true}
      />
      <mesh ref={cloudsRef}>
        <sphereGeometry args={[5.1, 50, 50]} />
        <meshPhongMaterial
          map={cloudsMap}
          opacity={0.1}
          depthWrite={true}
          transparent={true}
          side={THREE.DoubleSide}
        />
      </mesh>
      <group ref={group}>
        <mesh
          ref={pointOne}
          position={[point_1.x, point_1.y, point_1.z]}
          onClick={(event) =>
            window.location.replace(
              "https://nspcc.ru/en",
              "_blank",
              "noreferrer"
            )
          }
          onPointerOver={(event) => setHoverOne(true)}
          onPointerOut={(event) => setHoverOne(false)}
        >
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredOne ? "yellow" : "green"}
          />
          {hoveredOne && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">St Petersburg </div>

              <div className="textContentBold">NEOSPCC</div>
              <div className="textContentYellow">Consensus Node</div>
            </Html>
          )}
        </mesh>

        <mesh
          ref={pointTwo}
          position={[point_2.x, point_2.y, point_2.z]}
          onClick={(event) =>
            window.open("https://axlabs.com", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverTwo(true)}
          onPointerOut={(event) => setHoverTwo(false)}
        >
          {hoveredTwo && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Zurich </div>
              <div className="textContentBold">AX Labs</div>
              <div className="textContentYellow">Consensus Node</div>
            </Html>
          )}
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredTwo ? "yellow" : "green"}
          />
        </mesh>

        <mesh
          ref={pointThree}
          position={[point_3.x, point_3.y, point_3.z]}
          onClick={(event) =>
            window.open("https://coz.io", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverThree(true)}
          onPointerOut={(event) => setHoverThree(false)}
        >
          {hoveredThree && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Denver </div>
              <div className="textContentBold">COZ</div>
              <div className="textContentYellow">Council Member</div>
            </Html>
          )}
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredThree ? "yellow" : "red"}
          />
        </mesh>
        <mesh
          ref={pointFour}
          position={[point_4.x, point_4.y, point_4.z]}
          onClick={(event) =>
            window.open("https://www.switcheo.com/", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverFour(true)}
          onPointerOut={(event) => setHoverFour(false)}
        >
          {hoveredFour && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Singapore </div>
              <div className="textContentBold">Switcheo</div>
              <div className="textContentYellow">Council Member</div>
            </Html>
          )}
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredFour ? "yellow" : "red"}
          />
        </mesh>
        <mesh
          ref={pointFive}
          position={[point_5.x, point_5.y, point_5.z]}
          onClick={(event) =>
            window.open("https://www.everstake.one/", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverFive(true)}
          onPointerOut={(event) => setHoverFive(false)}
        >
          {hoveredFive && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Ukraine </div>
              <div className="textContentBold">Everstake</div>

              <div className="textContentYellow">Council Member</div>
            </Html>
          )}
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredFive ? "yellow" : "red"}
          />
        </mesh>
        <mesh
          ref={pointSix}
          position={[point_6.x, point_6.y, point_6.z]}
          onClick={(event) =>
            window.open("https://nash.io/", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverSix(true)}
          onPointerOut={(event) => setHoverSix(false)}
        >
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredSix ? "yellow" : "red"}
          />
          {hoveredSix && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Netherlands</div>
              <div className="textContentBold">nash_io</div>
              <div className="textContentYellow">Council Member</div>
            </Html>
          )}
        </mesh>

        <mesh
          ref={pointSeven}
          position={[point_7.x, point_7.y, point_7.z]}
          onClick={(event) =>
            window.open("https://github.com/neo-ngd", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverSeven(true)}
          onPointerOut={(event) => setHoverSeven(false)}
        >
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredSeven ? "yellow" : "green"}
          />
          {hoveredSeven && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Shanghai </div>
              <div className="textContentBold">The NEO Order</div>
              <div className="textContentYellow">Consensus Node</div>
            </Html>
          )}
        </mesh>
        <mesh
          ref={pointEight}
          position={[point_8.x, point_8.y, point_8.z]}
          onClick={(event) =>
            window.open("https://infstones.com/", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverEight(true)}
          onPointerOut={(event) => setHoverEight(false)}
        >
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredEight ? "yellow" : "red"}
          />
          {hoveredEight && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Dallas </div>
              <div className="textContentBold">Infstones</div>
              <div className="textContentYellow">Council Member</div>
            </Html>
          )}
        </mesh>
        <mesh
          ref={pointNine}
          position={[point_9.x, point_9.y, point_9.z]}
          onClick={(event) =>
            window.open(
              "https://flamingo.finance/</group>",
              "_blank",
              "noreferrer"
            )
          }
          onPointerOver={(event) => setHoverNine(true)}
          onPointerOut={(event) => setHoverNine(false)}
        >
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredNine ? "yellow" : "red"}
          />
          {hoveredNine && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Norway </div>
              <div className="textContentBold">Flamingo</div>

              <div className="textContentYellow">Council Member</div>
            </Html>
          )}
        </mesh>
        <mesh
          ref={pointTen}
          position={[point_10.x, point_10.y, point_10.z]}
          onClick={(event) =>
            window.open("https://neoline.io", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverTen(true)}
          onPointerOut={(event) => setHoverTen(false)}
        >
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredTen ? "yellow" : "red"}
          />
          {hoveredTen && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">China </div>
              <div className="textContentBold">NeoNext/ Neoline</div>

              <div className="textContentYellow">Council Member</div>
            </Html>
          )}
        </mesh>
        <mesh
          ref={pointEleven}
          position={[point_11.x, point_11.y, point_11.z]}
          onClick={(event) =>
            window.open("https://binance.com", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverEleven(true)}
          onPointerOut={(event) => setHoverEleven(false)}
        >
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredEleven ? "yellow" : "red"}
          />
          {hoveredEleven && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Malta </div>
              <div className="textContentBold">Binance</div>

              <div className="textContentYellow">Council Member</div>
            </Html>
          )}
        </mesh>
        <mesh
          ref={pointTwelve}
          position={[point_12.x, point_12.y, point_12.z]}
          onClick={(event) =>
            window.open("chainnode.com", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverTwelve(true)}
          onPointerOut={(event) => setHoverTwelve(false)}
        >
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredTwelve ? "yellow" : "red"}
          />
          {hoveredTwelve && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">China </div>
              <div className="textContentBold">ChainNode</div>

              <div className="textContentYellow">Council Member</div>
            </Html>
          )}
        </mesh>
        <mesh
          ref={pointThirteen}
          position={[point_13.x, point_13.y, point_13.z]}
          onClick={(event) =>
            window.open("https://neoburger.io/", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverThirteen(true)}
          onPointerOut={(event) => setHoverThirteen(false)}
        >
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredThirteen ? "yellow" : "red"}
          />
          {hoveredThirteen && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">China </div>
              <div className="textContentBold">NeoBurger</div>

              <div className="textContentYellow">Council Member</div>
            </Html>
          )}
        </mesh>
        <mesh
          ref={pointFourteen}
          position={[point_14.x, point_14.y, point_14.z]}
          onClick={(event) =>
            window.open("https://github.com/neo-ngd", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverFourteen(true)}
          onPointerOut={(event) => setHoverFourteen(false)}
        >
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredFourteen ? "yellow" : "grey"}
          />
          {hoveredFourteen && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Singapore </div>
              <div className="textContentBold">NGD2</div>
              <div className="textContentYellow">Candidate</div>
            </Html>
          )}
        </mesh>
        <mesh
          ref={pointFifteen}
          position={[point_15.x, point_15.y, point_15.z]}
          onClick={(event) =>
            window.open("https://ghostmarket.io", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverFifteen(true)}
          onPointerOut={(event) => setHoverFifteen(false)}
        >
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredFifteen ? "yellow" : "grey"}
          />
          {hoveredFifteen && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Finland </div>
              <div className="textContentBold">Ghost Market</div>
              <div className="textContentYellow">Candidate</div>
            </Html>
          )}
        </mesh>
        <mesh
          ref={pointSixteen}
          position={[point_16.x, point_16.y, point_16.z]}
          onClick={(event) =>
            window.open("https://www.neonewstoday.com/", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverSixteen(true)}
          onPointerOut={(event) => setHoverSixteen(false)}
        >
          {hoveredSixteen && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Denver </div>
              <div className="textContentBold">NEO News Today</div>
              <div className="textContentYellow">Council Member</div>
            </Html>
          )}
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredSixteen ? "yellow" : "red"}
          />
        </mesh>
        <mesh
          ref={pointSeventeen}
          position={[point_17.x, point_17.y, point_17.z]}
          onClick={(event) =>
            window.open(
              "https://https://red4sec.com/en/",
              "_blank",
              "noreferrer"
            )
          }
          onPointerOver={(event) => setHoverSeventeen(true)}
          onPointerOut={(event) => setHoverSeventeen(false)}
        >
          {hoveredSeventeen && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Madrid </div>
              <div className="textContentBold">Red4Sec</div>
              <div className="textContentYellow">GrantShares DAO Member</div>
            </Html>
          )}
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredSeventeen ? "yellow" : "blue"}
          />
        </mesh>
        <mesh
          ref={pointEighteen}
          position={[point_18.x, point_18.y, point_18.z]}
          onClick={(event) =>
            window.open("https://neoresearch.io/", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverEighteen(true)}
          onPointerOut={(event) => setHoverEighteen(false)}
        >
          {hoveredEighteen && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Brazil </div>
              <div className="textContentBold">NEO Research</div>
              <div className="textContentYellow">GrantShares DAO Member</div>
            </Html>
          )}
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredEighteen ? "yellow" : "blue"}
          />
        </mesh>
        <mesh
          ref={pointNineteen}
          position={[point_19.x, point_19.y, point_19.z]}
          onClick={(event) =>
            window.open("https://ngdenterprise.com/", "_blank", "noreferrer")
          }
          onPointerOver={(event) => setHoverNineteen(true)}
          onPointerOut={(event) => setHoverNineteen(false)}
        >
          {hoveredNineteen && (
            <Html scaleFactor={10} className="content">
              <div className="textContent">Redmond, WA </div>
              <div className="textContentBold">NGD Enterprise</div>
              <div className="textContentYellow">GrantShares DAO Member</div>
            </Html>
          )}
          <boxGeometry args={[0.1, 0.1, 0.4]} />
          <meshPhongMaterial
            opacity={0.6}
            depthWrite={true}
            transparent={true}
            side={THREE.DoubleSide}
            color={hoveredNineteen ? "yellow" : "red"}
          />
        </mesh>
        <line ref={pointLine}>
          <tubeGeometry args={[sausage_1, 20, 0.01, 8, false]} />
          <lineBasicMaterial
            opacity={0.1}
            linewidth={0.5}
            fog={false}
            color="#90F061"
            transparent={true}
          />
        </line>
        <line ref={pointLine}>
          <tubeGeometry args={[sausage_2, 20, 0.01, 8, false]} />
          <lineBasicMaterial
            opacity={0.1}
            linewidth={0.5}
            fog={false}
            color="#90F061"
            transparent={true}
          />
        </line>
        <line ref={pointLine}>
          <tubeGeometry args={[sausage_3, 20, 0.01, 8, false]} />
          <lineBasicMaterial
            opacity={0.1}
            linewidth={0.5}
            fog={false}
            color="#90F061"
            transparent={true}
          />
        </line>
        <line ref={pointLine}>
          <tubeGeometry args={[sausage_4, 20, 0.01, 8, false]} />
          <lineBasicMaterial
            opacity={0.1}
            linewidth={0.5}
            fog={false}
            color="#90F061"
            transparent={true}
          />
        </line>
        <line ref={pointLine}>
          <tubeGeometry args={[sausage_5, 20, 0.01, 8, false]} />
          <lineBasicMaterial
            opacity={0.1}
            linewidth={0.5}
            fog={false}
            color="#90F061"
            transparent={true}
          />
        </line>
        <line ref={pointLine}>
          <tubeGeometry args={[sausage_6, 20, 0.01, 8, false]} />
          <lineBasicMaterial
            opacity={0.1}
            linewidth={0.5}
            fog={false}
            color="#90F061"
            transparent={true}
          />
        </line>
        <line ref={pointLine}>
          <tubeGeometry args={[sausage_7, 20, 0.01, 8, false]} />
          <lineBasicMaterial
            opacity={0.1}
            linewidth={0.5}
            fog={false}
            color="#90F061"
            transparent={true}
          />
        </line>
        <line ref={pointLine}>
          <tubeGeometry args={[sausage_8, 20, 0.01, 8, false]} />
          <lineBasicMaterial
            opacity={0.1}
            linewidth={0.5}
            fog={false}
            color="#90F061"
            transparent={true}
          />
        </line>
        <line ref={pointLine}>
          <tubeGeometry args={[sausage_9, 20, 0.01, 8, false]} />
          <lineBasicMaterial
            opacity={0.1}
            linewidth={0.5}
            fog={false}
            color="#90F061"
            transparent={true}
          />
        </line>
        <line ref={pointLine}>
          <tubeGeometry args={[sausage_10, 20, 0.01, 8, false]} />
          <lineBasicMaterial
            opacity={0.1}
            linewidth={0.5}
            fog={false}
            color="#90F061"
            transparent={true}
          />
        </line>
        <line ref={pointLine}>
          <tubeGeometry args={[sausage_11, 20, 0.01, 8, false]} />
          <lineBasicMaterial
            opacity={0.1}
            linewidth={0.5}
            fog={false}
            color="#90F061"
            transparent={true}
          />
        </line>
      </group>
      <group rotation-y={-Math.PI / 2}>
        {/*       This is required in order to orient the map correctly so that points will work! ---- https://stackoverflow.com/questions/73670850/how-to-rotate-entire-canvas-in-react-js-with-react-three-fiber
         */}{" "}
        <mesh ref={earthRef}>
          <sphereGeometry args={[5, 50, 50]} />

          <meshPhongMaterial specularMap={specularMap} />
          <meshStandardMaterial
            map={colorMap}
            normalMap={normalMap}
            metalness={0.4}
            roughness={0.7}
          />

          <OrbitControls
            enableZoom={true}
            enablePan={true}
            enableRotate={true}
            zoomSpeed={0.6}
            panSpeed={0.5}
            rotateSpeed={0.4}
            /*             target={[0, 0, 0]}
             */
          />
        </mesh>
        {/*  <PerspectiveCamera
          //makeDefault
          ref={mainCamera}
          //up={[0, 0, 1]}
          //fov={40}
          //near={10}
          //far={100}
          //visible={false}
          position={[0, 0, 105]}
        ></PerspectiveCamera> */}
      </group>
    </>
  );
}

export default function TextureBackup() {
  return (
    <>
      <div class="grid-container">
        <div class="grid-item-header">
          <div class="htmlText">NEO Governance Structure 3D Map</div>
          <div class="htmlTextSmall">https://governance.neo.org/#/</div>
        </div>
        <Canvas
          class="cameraSettings"
          camera={{ position: [0, 0, 10], up: [0, 2, 0] }}
        >
          <Suspense fallback={null}>
            <Scene />
          </Suspense>
        </Canvas>
      </div>
    </>
  );
}
