import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

/* import Home from "./components/Home";
import World from "./components/World";
import Globe from "./components/Globe";
import Box from "./components/Box";
import Layout from "./components/Layout";
import Gsap from "./components/Gsap";
import Gsap2 from "./components/Gsap2";
import CanvasDemo from "./components/CanvasDemo";
import Texture from "./components/Texture"; */
import TextureBackup from "./components/TextureBackup";
/* import NewCard from "./components/NewCard";
 */
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TextureBackup />} />
        {/*    <Route path="/World" element={<World />} />
        <Route path="/Globe" element={<Globe />} />
        <Route path="/Box" element={<Box />} />
        <Route path="/Layout" element={<Layout />} />
        <Route path="/Gsap" element={<Gsap />} />
        <Route path="/Gsap2" element={<Gsap2 />} />
        <Route path="/CanvasDemo" element={<CanvasDemo />} />
        <Route path="/Texture" element={<Texture />} />
        <Route path="/TextureBackup" element={<TextureBackup />} />
        <Route path="/NewCard" element={<NewCard />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
